import { client } from "@/pim";
import {
  BookContent,
  ProductContent,
  SKU,
  SubscriptionContent,
  SubscriptionOverride,
} from "./types";
import { sanityFetch } from "@/cms/sanity.fetch";
import Logger from "@cappelendamm/logger";

import {
  BookProduct,
  SubscriptionProduct,
  getSubscriptionProduct as getSubscriptionProductPim,
} from "@cappelendamm/e-commerce-services";
import { getBookProduct as getBookProductPim } from "@cappelendamm/e-commerce-services";
import groq from "groq";
import { SubscriptionPageContent } from "@/app/[experimentGroup]/[universeTag]/(shop)/subscriptions/[slug]/SubscriptionPage";
import { createOverriddenSubscription } from "@/utils/overrides";

export const productContent = groq`
  _id,
  sku,
  image{
    asset->
  },
  description,
  "slug": slug.current,
  "type": _type
`;

export const subscriptionProductContent = groq`
${productContent},
    name,
    subscriptionType,
    books[]->{
      sku, 
      numberInSeries,
      contributors[]{
          "name": authorRef->name,
          "slug": authorRef->slug.current,
          "image": authorRef->image{
            asset->
          },
          "biography": authorRef->biography,
          contributorType,
      },
      "name": title,
      "slug": slug.current,
      
      image{
        asset->
      }
    },
    "giftCount": signUpGifts.count,
    "gifts": signUpGifts.products[]->{
        sku,                            
        image{
          asset->
        },
        "name": title,
        "type": "product",
        description,
        "slug": slug.current,
    },
    sections[]{
      "_id": _ref,
      _key,
      _type,
    },
`;

export const bookProductContent = groq`
  ${productContent},
  "name": title,
  contributors[]{
    "name": authorRef->name,
    "slug": authorRef->slug.current,
    "image": authorRef->image{
      asset->
    },
    "biography": authorRef->biography,
    contributorType,
  },
  categories[]->{
    name,
    path,
    parent {
      path,
      name
    }
  },
  "tags": {
    binding,
    publicationYear,
    publisher,
    language,
    "isbn": sku,
    numberOfPages,
    originalTitle,
    seriesName,
    numberInSeries,
  }
`;

export const productContentQuery = groq`*[sku in $skus || (slug.current == $slug && _type in ["subscription", "book", "product"])]{
  _type == "book" => @{
    ${bookProductContent}
  },
  _type == "subscription" => @{
    ${subscriptionProductContent}
  },
  _type == "product" => @{
    "name": title,
    ${productContent}
  }
}
`;

export const fetchProductContentBySlug = async <
  U extends ProductContent | SubscriptionContent,
>(
  slug: string,
) => {
  const items = await sanityFetch<U[]>({
    query: productContentQuery,
    params: {
      slug,
      skus: [],
    },
    tags: [slug],
  });
  if (items.length === 0) return null;
  return items[0];
};

export const fetchProductContentBySkus = async <
  U extends ProductContent | SubscriptionContent,
>(
  skus: SKU[],
) => {
  if (skus.length === 0) return [];
  const content = await sanityFetch<U[]>({
    query: productContentQuery,
    params: {
      skus,
      slug: "",
    },
    tags: skus,
  });
  return content;
};

export const fetchBookBySku = async (sku: SKU) => {
  const content = await sanityFetch<BookContent>({
    query: groq`*[
      sku == $sku && _type == "book"
    ][0]{
      ${bookProductContent},
    }`,
    params: {
      sku,
    },
    tags: [sku],
  });
  return content;
};

export const fetchSubscriptionProductBySku = async (sku: SKU) => {
  try {
    const pim = await getSubscriptionProductPim({ sku }, { client });
    return pim;
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const fetchSubscriptionOverrideBySku = async (
  sku: string,
  universeTag: string,
): Promise<SubscriptionOverride | null> => {
  const overrideQuery = groq`*[_type == "subscriptionOverridePage" && sku._ref == $sku && universe->tag.current == $universeTag][0]{
    overridePriceText,
    preamble,
    image {
      asset->
    }
  }`;

  const result = await sanityFetch<SubscriptionOverride>({
    query: overrideQuery,
    params: { sku, universeTag },
    tags: [sku, universeTag],
  });

  return result || null;
};

export const fetchBookProduct = async (sku: SKU) => {
  try {
    const pim = getBookProductPim({ sku }, { client });
    return pim;
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const fetchSkuBySlug = async ({ slug }: { slug: string }) => {
  const response = await sanityFetch<{ sku: SKU } | null>({
    query: groq`*[_type == "book" && slug.current == $slug][0]{sku}`,
    params: { slug },
    tags: [slug],
  });
  return response?.sku ?? null;
};

export const fetchBookProducts = async (skus: string[]) => {
  const results = await Promise.all(skus.map(fetchBookProduct));
  const books = results.reduce<BookProduct[]>((acc, cur, index) => {
    if (cur) return [...acc, cur];
    Logger.warn(`Books not found: ${skus[index]}`);
    return acc;
  }, []);
  return books;
};

export const getSubscriptionOverrideData = async (
  pageContent: SubscriptionPageContent,
  subscription: SubscriptionProduct,
  universeTag: string,
) => {
  if (!pageContent.sku) {
    return {
      updatedPageContent: pageContent,
      overriddenSubscription: subscription,
    };
  }
  const subscriptionOverride = await fetchSubscriptionOverrideBySku(
    pageContent.sku,
    universeTag,
  );

  const updatedPageContent = {
    ...pageContent,
    image: subscriptionOverride?.image ?? pageContent.image,
  };

  const overriddenSubscription = createOverriddenSubscription(
    subscription,
    subscriptionOverride?.overridePriceText,
  );

  return { updatedPageContent, overriddenSubscription };
};
