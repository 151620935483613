import { reduceCartItems } from "./reducer";
import { Cart, CartItem } from "./types";
import { getCookie, setCookie } from "@cappelendamm/cookie";
import { trackCartEvent } from "./tracker";
import { INITIAL_CART } from "./constants";
import { SKU } from "@/products/types";

export const CART_COOKIE_NAME = "cart";

export const serializeCart = JSON.stringify;
export const deserializeCart = (cart: string): Cart =>
  JSON.parse(cart || JSON.stringify(INITIAL_CART));

const getCart = (): Cart => {
  const cart = getCookie(CART_COOKIE_NAME);
  const { items = [], gifts = [], startBook = "" } = deserializeCart(cart);
  return { items, gifts, startBook };
};

const setCart = (cart: Cart): Cart => {
  setCookie({ name: CART_COOKIE_NAME, value: serializeCart(cart) });
  return cart;
};

const addItems = (items: CartItem[], newStartBook?: SKU): Cart => {
  const { items: cartItems, ...rest } = getCart();
  const cart = setCart({
    items: reduceCartItems(cartItems, { items, type: "add" }),
    startBook: newStartBook,
    ...rest,
  });
  trackCartEvent(
    "add_to_cart",
    items.map(({ sku }) => sku),
    cart,
  );
  return cart;
};

const addGifts = (items: CartItem[]): Cart => {
  const { gifts: cartItems, ...rest } = getCart();
  const cart = setCart({
    gifts: reduceCartItems(cartItems, { items, type: "add" }),
    ...rest,
  });
  trackCartEvent(
    "add_to_cart",
    items.map(({ sku }) => sku),
    cart,
  );
  return cart;
};

const removeItems = (items: CartItem[]): Cart => {
  const { items: cartItems, ...rest } = getCart();
  const cart = setCart({
    items: reduceCartItems(cartItems, { items, type: "remove" }),
    ...rest,
  });
  trackCartEvent(
    "remove_from_cart",
    items.map(({ sku }) => sku),
    cart,
  );
  return cart;
};

const removeGifts = (items: CartItem[]): Cart => {
  const { gifts: cartItems, ...rest } = getCart();
  const cart = setCart({
    gifts: reduceCartItems(cartItems, { items, type: "remove" }),
    ...rest,
  });
  trackCartEvent(
    "remove_from_cart",
    items.map(({ sku }) => sku),
    cart,
  );
  return cart;
};

const clearCart = (): Cart => {
  const cart = setCart(INITIAL_CART);
  return cart;
};

const cart = {
  getCart,
  setCart,
  clearCart,
  addItems,
  addGifts,
  removeItems,
  removeGifts,
};

export type CartMethods = typeof cart;

export default cart;
