import { SKU } from "@/products/types";
import { CartItem } from "./types";

export const reduceCartItems = (
  state: CartItem[],
  { type, items }: { type: "add" | "remove"; items: CartItem[] },
): CartItem[] => {
  const stateRecord = state.reduce<Record<SKU, number>>(
    (acc, cur) => ({
      ...acc,
      [cur.sku]: (acc[cur.sku] ?? 0) + cur.quantity,
    }),
    {},
  );

  const factor = type === "remove" ? -1 : 1;
  const itemsRecord = items.reduce<Record<SKU, number>>(
    (acc, cur) => ({
      ...acc,
      [cur.sku]: (acc[cur.sku] ?? 0) + cur.quantity * factor,
    }),
    stateRecord,
  );
  return Object.entries(itemsRecord).reduce<CartItem[]>(
    (acc, [sku, quantity]) =>
      quantity <= 0 ? acc : [...acc, { sku, quantity }],
    [],
  );
};
