import { ProductContent } from "@/products/types";
import { Cart, CartItem } from "./types";
import { productContent } from "@/products";
import { sanityFetch } from "@/cms";
import groq from "groq";

export const getCartItemsData = async (
  cart: Cart,
): Promise<(ProductContent & CartItem)[]> => {
  const products = await sanityFetch<ProductContent[]>({
    query: groq`*[_type in ["book", "subscription"] && sku in $skus]{
    ${productContent}
  }`,
    params: { skus: cart.items.map(({ sku }) => sku) },
    tags: cart.items.map(({ sku }) => sku),
  });

  return products.map((product) => ({
    quantity: 0,
    ...cart.items.find((item) => item.sku === product?.sku),
    ...product,
  }));
};
