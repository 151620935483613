import(/* webpackMode: "eager", webpackExports: ["BackToUniverseSelection"] */ "/vercel/path0/apps/netthandel/src/app/BackToUniverseSelection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExperimentToggle"] */ "/vercel/path0/apps/netthandel/src/app/ExperimentToggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreviewToggle"] */ "/vercel/path0/apps/netthandel/src/app/PreviewToggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UniverseSelect"] */ "/vercel/path0/apps/netthandel/src/app/UniverseSelect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider"] */ "/vercel/path0/apps/netthandel/src/cart/components/CartProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/cms/live-preview/PreviewProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreloadFonts"] */ "/vercel/path0/apps/netthandel/src/fonts/preload.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AutoTracker"] */ "/vercel/path0/apps/netthandel/src/tracking/AutoTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/tailwindcss/tailwind.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/fonts/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
