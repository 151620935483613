import { GraphQLClient } from "graphql-request";
import { type Client } from "@cappelendamm/e-commerce-services";
import { type RequestConfig } from "graphql-request/build/esm/types";

const tenantIdentifier = process.env.NEXT_PUBLIC_CRYSTALLIZE_TENANT_ID ?? "";
const tenantId = process.env.CRYSTALLIZE_TENANT_ID ?? "";
const apiUrlBase = `https://api.crystallize.com/${tenantIdentifier}`;
const authHeaders = {
  "X-Crystallize-Access-Token-Id":
    process.env.CRYSTALLIZE_ACCESS_TOKEN_ID ?? "",
  "X-Crystallize-Access-Token-Secret":
    process.env.CRYSTALLIZE_ACCESS_TOKEN_SECRET ?? "",
};

const getClient = (uri: string, requestConfig?: RequestConfig) => {
  return new GraphQLClient(uri, {
    cache: "no-store",
    ...requestConfig,
  });
};

export const catalogueClient = getClient(`${apiUrlBase}/catalogue`, {
  headers: authHeaders,
});
export const searchClient = getClient(`${apiUrlBase}/search`, {
  headers: authHeaders,
});
export const pimClient = getClient(`https://pim.crystallize.com/graphql`, {
  headers: authHeaders,
});
export const subscriptionsClient = getClient(`${apiUrlBase}/subscriptions`, {
  headers: authHeaders,
});
export const ordersClient = getClient(`${apiUrlBase}/orders`, {
  headers: authHeaders,
});
export const client: Client = {
  tenantId,
  pim: pimClient,
  search: searchClient,
  catalogue: catalogueClient,
  subscriptions: subscriptionsClient,
  orders: ordersClient,
};
