import { ProductContent, SKU } from "@/products/types";
import { reduceCartItems } from "./reducer";
import { Cart, CartItem } from "./types";
import { SubscriptionProduct } from "@cappelendamm/e-commerce-services";

export const getCartFromSkus = (skus: string[]): Cart => ({
  items: skus.map((sku) => ({ sku, quantity: 1 })),
  gifts: [],
});

export const getCartItemPrice = (
  cur: Partial<SubscriptionProduct & ProductContent>,
): number => {
  return (
    cur.signUpPrice?.price ??
    cur.recurringPrice?.price ??
    cur?.price?.value ??
    0
  );
};

export const getCartItemsTotal = (
  items: (Partial<ProductContent> & CartItem)[],
): number =>
  items.reduce((acc, cur) => acc + cur.quantity * getCartItemPrice(cur), 0);

export const getCartItemQuantity = (items: CartItem[], sku: SKU): number =>
  items.find((item) => item.sku === sku)?.quantity ?? 0;

export const getCombinedCart = (a: Cart, b: Cart): Cart => ({
  ...a,
  items: reduceCartItems(a.items, { type: "add", items: b.items }),
});
