"use client";
import { useEffect } from "react";
import { usePathname, useSearchParams, useRouter } from "next/navigation";
import { useCart } from "./useCart";

export const useLoadCartFromUrl = () => {
  const searchParams = useSearchParams();
  const cartItems = searchParams?.getAll("item");
  const { replace } = useRouter();
  const pathname = usePathname();
  const { addItems } = useCart();

  useEffect(() => {
    // if (cartItems && cartItems.length > 0)
    //   addItems(cartItems.map((item) => ({ sku: item, quantity: 1 })));
    // if (pathname && cartItems && cartItems.length > 0) replace(pathname);
  }, [cartItems, pathname, replace, addItems]);
};
