"use client";

import { FC, Suspense, useState } from "react";
import cartMethods from "../cart";
import { Cart } from "../types";
import { ProviderProps } from "react";
import { CartContext, CartContextType } from "../context";
import { useLoadCartFromUrl } from "../useLoadCartFromUrl";

const CartLoader: FC = () => {
  useLoadCartFromUrl();
  return <></>;
};

export const CartProvider: FC<
  Pick<ProviderProps<CartContextType>, "children">
> = ({ children }) => {
  const [cart, setCart] = useState<Cart>({ items: [], gifts: [] });
  const refreshCart = () => {
    setCart(cartMethods.getCart());
  };
  return (
    <CartContext.Provider value={{ refreshCart, ...cart }}>
      {children}
      <Suspense>
        <CartLoader />
      </Suspense>
    </CartContext.Provider>
  );
};
