"use client";
import { createContext, useContext } from "react";
import { CartMethods, default as cartMethods } from "./cart";
import type { Cart } from "./types";

export type CartContextType = Cart & { refreshCart: () => void };

export const CartContext = createContext<CartContextType>({
  items: [],
  gifts: [],
  refreshCart: () => undefined,
});

export const useCart = (): Cart & CartMethods => {
  const { refreshCart, ...cart } = useContext(CartContext);

  const wrapWithRefreshCart = <T extends Array<any>, U>(
    fn: (...args: T) => U,
  ) => {
    return (...args: T): U => {
      const result = fn(...args);
      refreshCart();
      return result;
    };
  };
  return {
    ...cart,
    getCart: wrapWithRefreshCart(cartMethods.getCart),
    setCart: wrapWithRefreshCart(cartMethods.setCart),
    clearCart: wrapWithRefreshCart(cartMethods.clearCart),
    addItems: wrapWithRefreshCart(cartMethods.addItems),
    removeItems: wrapWithRefreshCart(cartMethods.removeItems),
    addGifts: wrapWithRefreshCart(cartMethods.addGifts),
    removeGifts: wrapWithRefreshCart(cartMethods.removeGifts),
  };
};
