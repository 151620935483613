import { Cart } from "./types";
import { getCartItemsData } from "./fetchers";
import { ProductContent, SKU } from "@/products/types";
import { getCartItemQuantity, getCartItemsTotal } from "./selectors";
import tracker from "@/tracking/tracker";
import {
  AddToCartEvent,
  ECommerceItem,
  RemoveFromCartEvent,
  ViewCartEvent,
} from "@/tracking/ga-properties";
import { SubscriptionProduct } from "@cappelendamm/e-commerce-services";

export const getCartTrackingItems = (
  products: ProductContent[],
  cart: Cart,
): ECommerceItem[] => {
  return products.map<ECommerceItem>(({ name, price, type, sku }, index) => {
    const quantity = getCartItemQuantity(cart.items, sku);
    return {
      item_id: sku,
      item_name: name,
      price: price.value,
      item_category: type,
      index,
      quantity,
      item_variant: sku,
    };
  });
};

export const trackCartEvent = async (
  eventName: "add_to_cart" | "remove_from_cart" | "view_cart",
  items: SKU[],
  cart: Cart,
): Promise<void> => {
  const cartItems = await getCartItemsData({
    ...cart,
    items: cart.items.filter(({ sku }) => items.some((item) => item === sku)),
  });
  const trackingItems = getCartTrackingItems(cartItems, cart);
  const total = getCartItemsTotal(cartItems);
  const event: AddToCartEvent | RemoveFromCartEvent | ViewCartEvent = {
    eventName,
    ecommerce: {
      currency: "NOK",
      value: total,
      items: trackingItems,
    },
  };
  tracker.trackEvent(event);
};

export const getTrackingItemsSubscriptionProduct = ({
  sku,
  name,
  type,
  recurringPrice,
}: SubscriptionProduct): ECommerceItem => {
  return {
    item_id: sku,
    item_name: name ?? sku,
    price: recurringPrice.price,
    item_category: type,
    index: 0,
    quantity: 1,
    item_variant: sku,
  };
};

export const getTrackingItemsSubscriptionGifts = (gifts: ProductContent[]) => {
  return gifts.map(({ sku, name, type }, index) => ({
    item_id: sku,
    item_name: name,
    price: 0,
    item_category: type,
    index: index + 1,
    quantity: 1,
    item_variant: sku,
  }));
};

export const getTrackingItemsOrderProducts = (
  products: ProductContent[],
): ECommerceItem[] => {
  return products.map(({ sku, name, type, price }, i) => ({
    item_id: sku,
    item_name: name ?? sku,
    price: price?.value ?? 0,
    item_category: type,
    index: i,
    quantity: 1,
    item_variant: sku,
  }));
};
